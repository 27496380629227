<template>
  <svg width="20" height="20" viewBox="0 0 19 20" :fill="active ?'#5959FF':'#AFACBF'" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M-8.74228e-08 2C-6.33815e-08 1.45 0.232552 0.979166 0.697657 0.587499C1.16276 0.195833 1.72188 -7.55251e-07 2.375 -7.26702e-07L16.625 -1.03815e-07C17.2781 -7.52655e-08 17.8372 0.195833 18.3023 0.5875C18.7674 0.979167 19 1.45 19 2L19 18C19 18.55 18.7674 19.0208 18.3023 19.4125C17.8372 19.8042 17.2781 20 16.625 20L2.375 20C1.72188 20 1.16276 19.8042 0.697656 19.4125C0.232551 19.0208 -8.10846e-07 18.55 -7.86805e-07 18L-8.74228e-08 2ZM2.375 2L2.375 6L16.625 6L16.625 2L2.375 2ZM2.375 8L2.375 12L16.625 12L16.625 8L2.375 8ZM2.375 14L2.375 18L16.625 18L16.625 14L2.375 14Z"
        :fill="active ?'#5959FF':'#AFACBF'"/>
  </svg>

</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>