<template>
  <div class="horizontal-table table-responsive-wrapper mt-7 mb-7">
    <Table
        :columns="columns"
        :data="filteredData"
        :loading="loading"
        :no-data-text="loading ? '' : $t('common.nodata')"
        class="table-responsive-wrapper-in"
    >
      <template slot="loading">
        <Loader/>
      </template>
      <template slot="plan" slot-scope="{ row }">
        <div class="d-flex align-center" :class="{'vip':row.isVIP}">
          <img :src="row.coinDto.logoUrl" alt="" class="me-4" width="36px" height="36px">
          <div>
            <p>{{ row.coinDto.displayName }}</p>
            <p> {{ row.description }}</p>
          </div>
        </div>
      </template>
      <template slot="apr" slot-scope="{row}">
        <div style="color: #0ACC5B" dir="ltr" class="font-Medium-16">
          {{
            row.details[0].minApy && row.details[0].maxApy ?
                `${row.details[0].minApy}% ≈  ${row.details[0].maxApy}%` :
                `${row.details[0].activeAPY}%`
          }}
        </div>

      </template>
      <template slot="date" slot-scope="{ row }">
        <div v-for="(duration, index) in row.durations" :key="index" class="duration-container">
          <div
              class="duration-item d-flex justify-center align-center"
              :class="
                    getActiveDuration(
                      row.stakingPlanId,
                      duration.planDetailId
                    ) && 'active'
                  "
              @click="setActiveDuration(row.stakingPlanId, duration.planDetailId)"
          >
            <div class="d-flex flex-direction-column align-center">
              <div>
                <span class="text-start font-Medium-14 me-1">{{ duration.duration }}</span>
                <span class="text-start font-Medium-14">{{ $t("staking.day") }}</span>
              </div>
              <p v-if="getActiveDuration(
                      row.stakingPlanId,
                      duration.planDetailId
                    )" class="font-Medium-12">% {{ row.details[0].activeAPY }}</p>
            </div>
          </div>
        </div>
      </template>
      <template slot="operation" slot-scope="{row}">
        <Button
            :class="[
                  'mt-4',
                  'mb-2',
                  'detail-btn',
                  {'disabled':row.details[0].isRegisterEnded || row.details[0].isRegisterNotStarted}
                ]"

            @click="showDetail(row)"
            :disabled="row.details[0].isRegisterEnded || row.details[0].isRegisterNotStarted"
        >
          <span>{{ getPlanStatus(row.details[0]) }}</span>
        </Button>
      </template>
    </Table>
    <div class="d-flex justify-center" v-if="!last">
      <Button
          class="mt-8 mb-8 load-more-btn"
          :loading="loadingMore"
          @click="loadMore"
      >
        <span>{{ $t("staking.show_more") }}</span>
      </Button>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/UI/LoadingComp";
import dayjs from "../../../plugins/dayjs";

export default {
  data() {
    return {
      columns: [
        {
          title: this.$t('staking.name_plan'),
          key: "plan",
          slot: "plan",
          width: 200,
          align: "right",
        },
        {
          title: "Min/Max APR",
          key: "apr",
          slot: "apr",
          align: "right",
          width: 150,
        },
        {
          title: this.$t('staking.time_frame'),
          key: "date",
          slot: "date",
          align: "center",
          width: 400,
        },
        {
          title: " ",
          key: "operation",
          slot: "operation",
          width: 120,
        },
      ],
      loading: false
    }
  },
  props: [
    "plansData",
    "totalPages",
    "last",
    "loadingDetail",
    "loadingMore",
    "listType",
  ],
  components: {
    Loader,
  },
  methods: {
    dayjs,
    dateFormat: function (tick, option) {
      return this.$options.filters.dateFormat(tick, option);
    },
    showDetail(plan) {
      this.$router.push({
        query: {
          plan: plan.stakingPlanId
        }
      })
      this.$emit("stakeModal", {
        type: "enter",
        planId: plan.stakingPlanId,
        durationId: plan.details[0].id,
      });
    },
    showSubscribedDetail(plan) {
      this.$emit("stakeModal", {
        type: "view",
        planId: plan.subscriptionId,
        durationId: plan.stakingPlanDetailId,
      });
    },
    loadMore() {

      this.$emit("loadMore");
    },
    getRemainCapacity(planId, planDetails) {
      const index = this.plansData.findIndex(
          (obj) => obj.stakingPlanId === planId
      );
      if (this.plansData[index].details[0].id === planDetails.id) {
        return (
            (100 * (planDetails.maxCapacity - planDetails.remainCapacity)) /
            planDetails.maxCapacity
        );
      }
    },
    getActiveDuration(planId, durationId) {
      return (
          this.plansData.findIndex(
              (obj) =>
                  obj.stakingPlanId === planId && obj.details[0].id === durationId
          ) > -1
      );
    },
    setActiveDuration(planId, durationId) {
      this.$emit("updateDetailByDuration", {planId, durationId});
    },
    getDetail(planId) {
      return this.plansData.find((obj) => obj.stakingPlanId === planId).details[0];
    },
    getPlanStatus(planStatus) {
      let status = "";
      planStatus.isRegisterNotStarted
          ? (status = this.$t("staking.registration_not_started"))
          : planStatus.remainCapacity === 0
              ? (status = this.$t("staking.filled_capacity"))
              : planStatus.isRegisterEnded
                  ? (status = this.$t("staking.registration_expired"))
                  : (status = this.$t("staking.enter_plan"));
      return status;
    },
    getSubscriptionStatus(status) {
      if (status === "ACTIVE") {
        return {className: "Success", text: this.$t("staking.status_active")};
      } else if (status === "TERMINATED") {
        return {
          className: "Terminate",
          text: this.$t("staking.status_terminated"),
        };
      } else if (status === "CANCELED") {
        return {className: "Error", text: this.$t("staking.status_cancel")};
      }
    },
    getRemainedDate(endTime) {
      const currentTime = new Date().getTime();
      return {
        day:
            Math.floor((endTime - currentTime) / (1000 * 60 * 60 * 24)) > 0
                ? Math.floor((endTime - currentTime) / (1000 * 60 * 60 * 24))
                : 0,
        hour:
            Math.floor(
                ((endTime - currentTime) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ) > 0
                ? Math.floor(
                    ((endTime - currentTime) % (1000 * 60 * 60 * 24)) /
                    (1000 * 60 * 60)
                )
                : 0,
        minute:
            Math.floor(
                ((endTime - currentTime) % (1000 * 60 * 60)) / (1000 * 60)
            ) > 0
                ? Math.floor(
                    ((endTime - currentTime) % (1000 * 60 * 60)) / (1000 * 60)
                )
                : 0,
      };
    },
  },
  computed: {
    filteredData() {
      return this.plansData.filter(item => item.details !== null);
    },
  },

  mounted() {
    const isQuery = this.$route.query.plan
    if (isQuery) {
      const plan = this.plansData.find(item => item.stakingPlanId == isQuery)
      this.$emit("stakeModal", {
        type: "enter",
        planId: +isQuery,
        durationId: +plan.details[0].id,
      });
    }
  }
};
</script>

<style lang="scss">
.horizontal-table {
  .ivu-table-wrapper {
    .ivu-table {
      .ivu-table-body {
        .ivu-table-tbody {
          .ivu-table-cell-slot {
            display: flex !important;
            align-items: center;
            gap: 16px;
          }
        }
      }
    }
  }
}


</style>

<style lang="scss" scoped>
.table-responsive-wrapper {
  .table-responsive-wrapper-in {
    min-height: 400px;

    .vip {
      background: linear-gradient(to left, #F1AC1CB2, #172232B2);
      border-radius: 8px;
      width: 203px;
      height: 69px;
      padding: 12px;
    }

    .duration-item {
      width: 109px;
      height: 52px;
      border: 1px solid #A2AEBF;
      border-radius: 8px;
      cursor: pointer;
    }

    .duration-item.active {
      border: 1px solid #F1AC1C !important;
      color: #F1AC1C !important;
    }

    .detail-btn {
      min-height: 40px;
      padding: 0 12px;
      border-radius: 8px;
      border: 1px solid #F1AC1C;
      color: #F1AC1C;
      min-width: 140px;
    }

    .disabled {
      border-color: transparent;
    }
  }
}
</style>
